.driveLayout {
    display: flex;
    margin: 1%;
    padding: 1%;
}
button {
    width: 100%;
    background-color: #f9e64b;
    border: none;
    color: black;
    padding: 15px 15px 15px 40px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px;
    cursor: pointer;
    background-image: url('https://upload.wikimedia.org/wikipedia/commons/1/12/Google_Drive_icon_%282020%29.svg');
    background-repeat: no-repeat;
    background-position: 6px 10px;
    background-size: 28px auto;
    vertical-align: middle;
    border-radius: 10px;
}
button#driveExport {
    padding: 15px 40px 15px 15px;
    background-position: 97% 10px;
    background-repeat: no-repeat;
    background-size: 28px auto;
}
button:hover {
    box-shadow: 0 0 0 1px #2222 inset, 0 0 0 3px #2222 inset
}
.driveiFrameLayout {
    width: 70%;
    height: 550px;
    margin: auto;
    /* border: 0; */
}
#content {
    display: block;
    color: #f96e4b;
}

@media (min-width: 320px) and (max-width: 1024px) {
    .driveLayout {
        display: block;
    }

    .driveiFrameLayout {
        width: 100%;
        height: 300px;
    }
}
